import axios from "axios";
import {
  BuybackResponse,
  BuybackReview,
  BuybackReviewResponse,
} from "../types/types";

import { fetchAuthSession } from 'aws-amplify/auth';

const getAuthHeaders = async () => {
  const session = await fetchAuthSession();
  const token = session.tokens?.accessToken.toString();
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8000";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Function to fetch buyback items
const getBuybackItems = async (size: number = 2, currentPage: number = 1, additionalParams: { [key: string]: string | string[] } = {}): Promise<BuybackResponse> => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback`, {
      params: {
        size,
          page: currentPage,
          ...additionalParams
      },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback items:", error);
    throw error;
  }
};

const upcomingBuybackData = async (duration: string="1W", size: number = 2, currentPage: number = 1): Promise<BuybackResponse> => {
  try{
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/upcoming`, {
      params: {
        duration,
        size,
        page: currentPage
      },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error upcoming buyback items:", error);
    throw error;
  }
};

const pastBuybackData = async (duration: string="1W", size: number = 2, currentPage: number = 1): Promise<BuybackResponse> => {
  try{
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/past`, {
      params: {
        duration,
        size,
        page: currentPage
      },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error past buyback items:", error);
    throw error;
  }
};

const searchBuybackByParam = async (searchParam: string): Promise<BuybackResponse> => {
  try{
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/search`, {
      params: {
        searchParam
      },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error search buyback items:", error);
    throw error;
  }
};

const searchBuybackReviewsByParam = async (searchParam: string): Promise<BuybackResponse> => {
  try{
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/reviews/search`, {
      params: {
        searchParam
      },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error search buyback reviews items:", error);
    throw error;
  }
};

const getBuybackReviewsByItemId = async (itemId: number): Promise<BuybackResponse> => {
  try{
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/${itemId}/reviews`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error search buyback items:", error);
    throw error;
  }
};

// Function to update a buyback item
const updateBuybackItem = async (id: number, data: object) => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.put(`/admin/buyback/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

const updateInventory = async (id: number, data: object) => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.put(`/admin/buyback/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

// Function to delete a buyback item
const deleteBuybackItem = async (id: number) => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.delete(`/admin/buyback/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting buyback item:", error);
    throw error;
  }
};

// Function to post a review for a buyback item
const postNewBuyback = async (buybackData:any) => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.post(`/admin/buyback`, buybackData, { headers });

    return response.data;
  } catch (error) {
    console.error("Error during add new buyback:", error);
    throw error;
  }
};

// Function to post a review for a buyback item
const postBuybackReview = async (
  id: number,
  reviewData: Omit<
    BuybackReview,
    "csReviewStatus" | "inspectionStatus" | "inspectionNotes"
  >
) => {
  try {
    const headers = await getAuthHeaders();
    const defaultData = {
      csReviewStatus: "PENDING",
      inspectionStatus: "PENDING",
      inspectionNotes: "Needs further inspection",
    };

    const response = await apiClient.post(`/admin/buyback/${id}/reviews`, {
      ...defaultData,
      ...reviewData, // Merge user-provided review data with default values
    }, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error posting buyback review:", error);
    throw error;
  }
};

const getBuybackReviews = async (size: number = 2, currentPage: number = 1, additionalParams: { [key: string]: string | string[] } = {}): Promise<BuybackReviewResponse> => {
  try {
    const headers = await getAuthHeaders();
    const response = await apiClient.get<BuybackReviewResponse>(
      "/admin/buyback/reviews",
      {
        params: {
          size,
          page: currentPage,
          ...additionalParams
        },
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback reviews:", error);
    throw error;
  }
};

// Function to update a review for a buyback item
const updateBuybackReview = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "csReviewStatus"> & { reviewComments?: string }
) => {
  try {
    const headers = await getAuthHeaders();
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};


const updateProductInspection = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "inspectionStatus" > & {
    customerComments: string;
    csReviewStatus: string;
    csReviewerId: number;
    // inventorySku: string;
    repairComments: string | string[];
    productCondition?: 'IN_GOOD_SHAPE' | 'REQUIRE_REPAIR';
    // inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
    paymentStatus: "PENDING" | "INITIATED" | "PAID" | "CANCELLED";
  }
) => {
  try {
    const headers = await getAuthHeaders();
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};

// Function to update payment status and final price of a buyback item
const updateBuybackPaymentStatus = async (
  id: number,
  finalBuybackPrice: string,
  paymentStatus: 'PENDING' | 'INITIATED' | 'PAID' | 'CANCELLED',
  paymentMethod: 'CASH' | 'CREDIT_CARD' | 'BANK_TRANSFER' | 'OTHER',
  inventorySku?: string,
  inventoryType?: "USED_PRODUCT" | "RENTAL_PRODUCT",
  buybackStatus?: string
) => {
  try {
    // Construct payload to match the exact structure required by the API
    const headers = await getAuthHeaders();
    const data = {
      notes: "",
      dateAcquired: "",
      status: "available",
      paymentMethod,
      inventoryType,
      inventorySku,
      finalBuybackPrice,
      paymentStatus,
      buybackStatus
    };

    const response = await apiClient.put(`/admin/buyback/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback payment status:", error);
    throw error;
  }
};

export {
  getBuybackItems,
  upcomingBuybackData,
  pastBuybackData,
  searchBuybackByParam,
  updateBuybackItem,
  deleteBuybackItem,
  postBuybackReview,
  getBuybackReviews,
  updateBuybackReview,
  updateProductInspection,
  updateBuybackPaymentStatus,
  updateInventory,
  getBuybackReviewsByItemId,
  searchBuybackReviewsByParam,
  postNewBuyback
};
