import React, { useState, FormEvent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postNewBuyback } from "../api/AdminApi";

const NewBuybackForm: React.FC = () => {
  // Form States
  const [orderId, setOrderId] = useState("");
  const [productName, setProductName] = useState("");
  const [productSku, setProductSku] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [orderDate, setOrderDate] = useState<Date | null>(null);
  const [buybackDate, setBuybackDate] = useState<Date | null>(null);
  const [buybackPrice, setBuybackPrice] = useState("");

  const formatDate = (date: Date | null) =>
    date ? date.toISOString().split("T")[0] : "";

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!orderId || !productName || !productSku || !firstName || !lastName || !email || !phone || !address || !orderDate || !buybackDate || !buybackPrice) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      const buybackData = {
        orderId,
        productName,
        productSku,
        customerFirstname: firstName,
        customerLastname:lastName,
        customerEmail: email,
        customerPhone: phone,
        customerAddress: address,
        purchaseDate: formatDate(orderDate),
        buybackDate: formatDate(buybackDate),
        buybackPrice,
        buybackStatus: "PENDING"
      };

      await postNewBuyback(buybackData);
      alert("Buyback data added successfully!");
      setOrderId("");
      setProductName("");
      setProductSku("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setOrderDate(null);
      setBuybackDate(null);
      setBuybackPrice("");
    } catch (error) {
      console.error("Error during add buyback data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="sm:ml-10 lg:ml-20 min-h-screen font-sans">
      <div className="container sm:px-5 lg:px-80 py-10">
        <h1 className="text-3xl font-bold text-center text-red-400 mb-8">
          New Buyback Form
        </h1>
        <div className="bg-white text-black rounded-lg shadow-lg p-8">
          <form onSubmit={handleSubmit}>
            {/* Order ID */}
            <div className="mb-4">
              <label className="block text-sm font-semibold text-gray-700 uppercase">Order ID:</label>
              <input
                type="text"
                required
                placeholder="Enter Order Id"
                className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>

            {/* Product Details */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Product Name:</label>
                <input
                  type="text"
                  required
                  placeholder="Enter Product Name"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Product SKU:</label>
                <input
                  type="text"
                  required
                  placeholder="Enter Product SKU"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                  value={productSku}
                  onChange={(e) => setProductSku(e.target.value)}
                />
              </div>
            </div>

            {/* Customer Details */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">First Name:</label>
                <input
                  type="text"
                  required
                  placeholder="Enter First Name"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Last Name:</label>
                <input
                  type="text"
                  required
                  placeholder="Enter Last Name"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            {/* Contact Details */}
            <div className="mb-4">
              <label className="block text-sm font-semibold text-gray-700 uppercase">Email:</label>
              <input
                type="email"
                required
                placeholder="Enter Email"
                className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold text-gray-700 uppercase">Phone:</label>
              <input
                type="number"
                required
                placeholder="Enter Customer Phone"
                className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-semibold text-gray-700 uppercase">Address:</label>
              <textarea
                placeholder="Enter Customer Address"
                className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            {/* Order & Buyback Dates */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Order Date:</label>
                <DatePicker
                  placeholderText="Select Order Date"
                  selected={orderDate}
                  onChange={setOrderDate}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Buyback Date:</label>
                <DatePicker
                  placeholderText="Select Buyback Date"
                  selected={buybackDate}
                  onChange={setBuybackDate}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
            </div>

            {/* Buyback Price & Status */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 uppercase">Buyback Price:</label>
                <input
                  placeholder="Enter Buyback Price"
                  required
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-red-400"
                  value={buybackPrice}
                  onChange={(e) => setBuybackPrice(e.target.value)}
                />
              </div>
            </div>

            <button type="submit" className="w-full bg-red-400 text-white py-3 rounded-full hover:bg-red-500">Submit Buyback</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewBuybackForm;
